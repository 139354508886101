const primario = "#004880";
const primarioMuyClaro = "#f3f7fd"; //celeste claro
const primarioClaro = "#c8d9f5"; //celeste
const secundario = "#F5DF4D";
const secundarioClaro = "#ffffec";
const primarioHover = "#014ecb";
const generalShadhow = "0";
const totalBorderRadius = "120px";
const generalBorderRadius = "12px";
const mediumBorderRadius = "12px";
const smallBorderRadius = "6px";
const generalBorder = "thin solid rgba(0, 0, 0, 0.2)";
const stop = "#ff354a";
const stopOscuro = "#590000";
const stopClaro = "#fee9eb";
const goMuyClaro = "#f9ffe5";
const go = "#00aa33";

export const loiTheme = {
  fondo: "#eaeded",
  overlay: "#dee0e0",
  negro: "#002e36",
  blanco: "#ffffff",
  primario, //azuloscuro
  primarioHover: primarioHover, //azul fuerte
  primarioClaro: primarioClaro, //celeste
  primarioMuyClaro: primarioMuyClaro,
  primariOscuro: "#051431", //azul muy muy oscuro
  secundario: secundario, //amarillo
  secundarioClaro: secundarioClaro, //amarillo muy claro
  peligroOscuro: "#3f0000",
  peligroClaro: "#fff5f5",
  estandarClaro: "#dce8ff",
  estandar: "#0c3071",
  estandarOscuro: "#02102a",
  inactivoClaro: "#f2f2f2",
  inactivo: "#d3d3d3",
  inactivoOscuro: "#424242",
  goMuyClaro: goMuyClaro,
  goClaro: "#d4ffe4",
  go: go,
  goOscuro: "#018227",
  waitClaro: "#fffcdd",
  wait: "#F5DF4D",
  waitOscuro: "#4a450c",
  stopClaro, //rojo claro
  stop, //rojo
  stopOscuro, //rojo oscruo
  cancelClaro: "#c2c2c2", //gris medio
  cancel: "#555555", //gris muy oscuro
  cancelOscuro: "#171717", //gris casi negro
  alert: "#ff8000",
  bordeBotonStandar: generalBorderRadius,
  tendenciasBorderRadius: generalBorderRadius,
  tendenciasBorder: generalBorder,
  //generales
  fondoPopUp: "rgba(12, 48, 113, 0.3)",
  smallMobileBreakPoint: "479px",
  generalMobileBreakPoint: "719px",
  mediumBreakPoint: "919px",
  bigBreakPoint: "1140px",
  maxBreakPoint: "1440px",
  body: "#eaeded",
  headersFont: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  bodyFonts: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  bodyFontsweight: "400",
  contenedorHomeTopMargin: 0,
  contenedorHomeTopMarginMobile: "0",
  generalShadhow: generalShadhow,
  generalBorderRadius: generalBorderRadius,
  mediumBorderRadius: mediumBorderRadius,
  smallBorderRadius: smallBorderRadius,
  verySmallBorderRadius: "3px",
  generalBorder: generalBorder,
  totalBorderRadius: totalBorderRadius,
  buttonBorderRadius: "6px",

  /*titulos de las secciones*/
  titulosDeSeccionesFont: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  titulosDeSeccionesFontWheigth: "600",
  titulosDeSeccionesFontSize: "1rem",
  titulosDeSeccionesFontSizeMobile: "1rem",
  titulosDeSeccionesPadding: "0",
  titulosDeSeccionesMargin: "0 0 10px 0",
  titulosDeSeccionesMarginMobile: "0 0 10px 0",
  titulosDeSeccionesHeroPadding: "0",
  titulosDeSeccionesHeroMargin: "0 0 10px 0",
  titulosDeSeccionesHeroMarginMobile: "0 0 10px 0",
  titulosDeSeccionesJustifyContent: "space-between",
  titulosDeSeccionesTextTransform: "none",
  titulosDeSeccionesBorderBottom: "none",
  /*margin de las secciones generales*/
  marginDeSeccionesGanerales: "10px 0",
  marginDeSeccionesGaneralesDesktop: "10px 15px",
  marginDeSeccionesGaneralesMobile: "5px 10px",

  /*boton add to cart*/
  addToCartTexto: "white",
  addToCartFondo: "#014ecb",
  addToCartBorder: "#014ecb",
  addToCartTextoHover: "white",
  addToCartFondoHover: "#004880",
  addToCartBorderHover: "#004880",

  /*boton outline*/
  botonOutlineTexto: "#014ecb",
  botonOutlineFondo: "white",
  botonOutlineBorder: "#014ecb",
  botonOutlineTextoHover: "white",
  botonOutlineFondoHover: "#014ecb",
  botonOutlineBorderHover: "#014ecb",

  //slidershome
  sliderNovedadesWidth: "calc(100% - 20px)",
  //productcard
  productCardTextAling: "left",
  productCardHoverEffect: "0 3px 3px rgba(0, 0, 0, 0.5)",
  //header
  headerOptionsMargin: "0 10px 0 0",
  headerOptionsMarginTablet: "0 5px",
  displayMensajeDeBienvenidaEnMobile: "none",
  headerLogoWidth: "80px",
  headerLogoHeigth: "42px",
  headerLogoWidthTablet: "80px",
  headerLogoHeigthTablet: "42px",
  headerLogoWidthMobile: "60px",
  headerLogoHeigthMobile: "32px",
  headerLogoImgageWidthMobile: "100%",
  headerLogoMargin: "0",
  headerLogoMarginMobile: "0",
  headerPadding: "20px 0 20px 10px",
  headerPaddingTablet: "20px 0",
  headerPaddingMobile: "10px 0",
  headerHeight: "70px",
  headerMobileHeight: "90px",
  headerMobileHeightCarrito: "50px",
  fondoHeader: secundario,
  fondoHeaderHover: secundarioClaro,
  headerSearchWidth: "100%",
  headerSearchWidthMobile: "100%",
  headerSearchBackground: "white",
  headerSearchBorder: "0",
  headerSearchMargin: "0",
  headerSearchMarginMobile: "0",
  headerSearchShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.3)",
  indicadorEnviandoA: "block",
  hearderCartIconmargin: "0 10px 0 5px",
  loginButtonMargin: "0 10px",
  favoritosButtonMargin: "0 10px",
  //menuMobile
  backgroundMenuMobile: "white",
  hoverbackgroundMenuMobile: primarioMuyClaro,
  buttonsColorMenuMobile: primario,
  mainButtonsBackgroundColorMenuMobile: primarioHover,
  //footermible
  avatarMobileBorderRadius: "50px",
  footerDisplay: "block",
  footerMobileVisibilityBreakPoint: "719px",
  footerBgColor: "#F5DF4D",
  footerInformationJustifyContent: "center",
  footerInformationTextAlign: "center",
  footerColorTextos: "black",
  footerColumnWidth: "31.333%",
  footerColumnMargin: "1%",
  footerOrderRedesInfoEmpresa: 2,
  footerOrderRedesSociales: 1,
  footerFlexDirection: "column",
  //selector de direcciones
  bordeSelectorDestinoDeEnvio: "0",
  paddingSelectorDestinoDeEnvio: "0",
  selectorDeDestinoPosicionMobile: "absolute",
  selectorDeDestinoTop: "50px",
  selectorDeDestinoMobileBorderRadius: "0",
  selectorDeDestinoMobileBoxShadow: "0",
  selectorDeDestinoMobileDisplayMode: "flex",
  selectorDeDestinoMobileBackground: "#F5DF4D",
  //sliders
  fondoFlechasSliders: `rgba(243, 247, 253, 0.6)`,
  fondoFlechasSlidersHover: primarioMuyClaro,
  colorIconoFlechaSlider: "blue",
  borderRadiusFlechaSliderDerecha: "20px 0 0 20px",
  borderRadiusFlechaSliderIzquierda: "0 20px 20px 0",
  shadowFlechaSlider: "0 0 30px -15px #014ecb;",
  shadowHoverFlechaSlider: " 0 0 30px -5px " + primarioHover,
  borderFlechaSlider: "thin solid #eee",
  borderHoverFlechaSlider: "0",
  BotonFlechaSliderNextRight: "-10px",
  BotonFlechaSliderNextLeft: "-10px",
  BotonFlechaSliderWidth: "30px",
  BotonFlechaSliderHeight: "20%",
  BotonFlechaSliderHover: primarioClaro,
  flechasConTodosLosBordes: false,
  //banners
  bannersHomeMargin: "10px 0 0 0",
  bannersHomeMarginDesktop: "15px 15px 0 15px",
  bannersHomeMarginMobile: "0",
  bannersHomeBorderRadius: "12px",
  bannersHomeBorderRadiusMobile: "0",
  bannerHomeSMAspectRatio: "720 / 400",
  bannerHomeMDAspectRatio: "1140 / 400",
  bannerHomeLGAspectRatio: "1920 / 450",
  //novedades por categorias
  novedadesPorCategoriaSliderMargin: 0,
  novedadesPorCategoriaSliderBorder: generalBorder,
  novedadesPorCategoriaSliderMargin: "0 0 15px 0",
  novedadesPorCategoriaSliderMarginMobile: "10px 0",
  novedadesPorCategoriaSliderWidth: "calc((100% - 15px) / 2)",
  novedadesPorCategoriaSliderWidthMobile: "100%",
  novedadesPorCategoriaFlexDirection: "column",
  //colecciones
  listaColeccionesHeaderBorderRadius: "0 0 6px 6px",
  coleccionTitleFontFamily: "",
  coleccionTitleTextTransform: "capitalize",
  coleccionTitleFontSize: "22px",
  coleccionFlexWrap: "nowrap",
  coleccionHeaderheight: "150px",
  coleccionWidth: "24%",
  coleccionWidthTablet: "45%",
  coleccionWidthMobile: "80%",
  coleccionBoxBoxBorderRadius: "6px",
  coleccionHeaderBorderRadius: "6px 6px 0 0",
  coleccionBorder: generalBorder,
  coleccionListaArticulosMargin: "0",
  coleccionHeaderFontSize: "",
  coleccionHeaderFontFamily: "",
  coleccionBackGround: "white",
  //tarjeta de producto
  tarjetaDeProductoImagenPadding: "0",
  tarjetaWidth: "18%",
  tarjetaPadding: "0.5%",
  tarjetaMargin: "0.5%",
  tarjetaWidth1140: "23%",
  tarjetaWidth719: "31.33333%",
  tarjetaWidth479: "48%",
  infoDeTipoDeEnvioTextAlign: "left",
  //textoseo
  textoSeoFontSize: "12px",
  textoSeoPadding: "0",
  textoSeoAlign: "left",
  //lista de tiendas oficiales
  tiendasHeaderBorderRadius: "0 0 20px 20px",
  tiendasHeaderTextAling: "center",
  tiendasHeaderAlingItems: "center",
  tiendasHeaderJustifyContent: "center",
  tiendasHeaderBgColor: primarioHover,
  tiendasHeaderColor: "white",
  tiendasHeaderPadding: "30px",
  //cuadro de tienda oficial
  tiendasCuadroLogoDisplay: "block",
  tiendaHeaderBorderRadius: " 14px 14px 0 0",
  //boton de iniciio de sesion
  fondoBotonLogin: primarioHover,
  borderColorBotonLogin: primarioHover,
  colorTextoBotonLogin: "white",
  hotSaleColor: stop,
  hotSaleColorOscuro: stopOscuro,
  hotSaleClaro: stopClaro,
  separacion: "15px",
  separacionLanding: "1rem",
  borderDefaultSlider: generalBorder,

  breakPointVerySmall: "479px",
  breakPointSmall: "719px",
  breakPointMedium: "919px",
  breakPointBig: "1140px",
  breakPointVeryBig: "1440px",

  descuentosPorMedioDePagoColor: "#6741d9",
  descuentosPorMedioDePagoColorClaro: "#b197fc",
  descuentosPorMedioDePagoColorMuyClaro: "#e8e0ff",
};
