import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Carrito from "../services/carrito";

//get datos carrito header
export const getCarritoHeader = createAsyncThunk(
  "carritoHeader/index",
  async (data, { rejectWhitValue }) => {
    try {
      const response = await Carrito.getCarritoHeader();
      return response;
    } catch (err) {
      const error_carrito_header = "ERROR_GET_CARRITO_HEADER";
      console.error(error_carrito_header, err);
      return rejectWhitValue({
        errorCarritoHeader: error_carrito_header,
      });
    }
  }
);


//agregar productos al carrito segun id y cantidad
export const agregarRestarProductoCarrito = createAsyncThunk(
  "agregarRestarProductoCarrito/index",
  async (data, { rejectWhitValue }) => {
    try {
      const response = await Carrito.agregarRestarProductoCarrito(data);
      return response;
    } catch (err) {
      const error_agregar_restar_carrito = "ERROR_AGRAGAR_RESTAR_CARRITO";
      return rejectWhitValue({
        errorProductCart: error_agregar_restar_carrito,
      });
    }
  }
);

const initialState = {
  //state carrito header
  carritoHeaderData: null,
  errorCarritoHeader: null,
  //end state carrito header
  //agregar restar producto carrito
  productCartData: null,
  errorProductCart: null,
  //end agregar restar producto carrito
  // set Agregar restart producto COMPONENTE LOCAL(NO SERVIDOR)
  productosEnCarrito: [],
  productoEnCarrito:null,
};

//slice


const carritoSlice = createSlice({
  name: "carrito",
  initialState,
  reducers: {
    resetCarrito: (state) => initialState,
    setCantidadProductoEnCarrito: (state, { payload }) => {
      /* esto me dice si el articulo ya esta en el carrito,
      devuelve un aobjeto con la info del articulo si esta en cart */
      let producto = state.productosEnCarrito.find(
        (item) => item.id === payload.id
      );

      /*### ACTUALIZAR DATOS EN HEADER ###//=> segun los datos retornados desde la condicional 
      hace la peticion al server y ejecuta la funcion agregarRestarProductoACarrito con los parametros requeridos*/

      const actualizarCarritoHeader = (cantidad, productoId) =>  (state.productoEnCarrito = { id: productoId, cantidad: cantidad});
      

      if (producto) {
        /* creo una var con todos los productos menos el que estamos agregando */
        let carritoSinProductoAEditar = state.productosEnCarrito.filter(
          (item) => item.id !== payload.id
        );
        /* agrego el nuevo */
        const cantidadCalculada =
          payload.accion === "AGREGAR"
            ? producto.cantidad + 1          
            :  producto.cantidad - 1;
            state.productosEnCarrito = [
              ...carritoSinProductoAEditar,
              {
                id: payload.id,
                cantidad: cantidadCalculada,
              },
            ];
           actualizarCarritoHeader(cantidadCalculada, payload.id );
      } else {
        actualizarCarritoHeader(1, payload.id);
        state.productosEnCarrito = [
          ...state.productosEnCarrito,
          { id: payload.id, cantidad: 1 },
        ];
      }
    }
  },
  extraReducers: {
    //get carrito header
    [getCarritoHeader.fulfilled]: (state, action) => {
      state.carritoHeaderData = action.payload;
      state.productosEnCarrito = action.payload.productos;
    },
    [getCarritoHeader.rejected]: (state, action) => {
      state.errorCarritoHeader = action.payload.error_carrito_header;
    },
    //end get carrito header
    [agregarRestarProductoCarrito.fulfilled]:(state, action) => {
      state.carritoHeaderData.cantidad = action.payload.cantidad_en_carrito;
    }
  },
});
export default carritoSlice;
