import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import loadable from '@loadable/component'

//styled components
import themeHandler from "../helpers/themeHandler";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/GlobalStyle";
// componentes de LOi
import Loading from "./Loading";
//provider
import { Provider } from "react-redux";
import store from "../redux/data";
//componentes

const Footer = loadable(/* webpackPrefetch: true */() => import("./Footer")); //SI USA LOADING


let container = document.getElementById("footer-placeholder");
const root = createRoot(container);

container &&
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={themeHandler(window.location.hostname)}>
        <GlobalStyle />
        <Suspense fallback={<Loading />}>
          <Footer />
        </Suspense>
      </ThemeProvider>
    </Provider>
  );
